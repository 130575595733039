import moment from '@/services/moment';
import { api } from './api';

const STUDIOS_BASE_URL = 'api/studios';
const V2_TEACHERS_BASE_URL = 'v2/teachers';

async function getRecentStudios(): Promise<StudioPreviewResponse[]> {
  const result = await api.get<StudioPreviewResponse[]>('api/recent-studios');
  return result.data;
}

async function getStudioDetail(studioURL: string): Promise<StudioResponse> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}`;
  const result = await api.get<StudioResponse>(url);
  return result.data;
}

async function sendInvitationEmails(emailList: string[]): Promise<void> {
  await api.post('api/teachers/me/invite_students', { emails: emailList });
}

async function getLesson(
  studioURL: string,
  productId: number,
  lessonId: number
): Promise<LessonResponse> {
  const result = await api.get<LessonResponse>(
    `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/lessons/${lessonId}`
  );
  return result.data;
}

async function completeTip(
  studioURL: string,
  defaultPaymentMethod: string,
  stripeCustomerId: string,
  tipAmount: string,
  reCaptchaToken: string
) {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/tips/tip`;
  const payload = {
    default_payment_method: defaultPaymentMethod,
    stripe_customer_id: stripeCustomerId,
    tip_amount: tipAmount,
    token: reCaptchaToken,
  };
  const result = await api.post(url, payload);
  return result.data;
}
async function createTipSetupIntent(
  studioURL: string,
  payload: any
): Promise<StripeIntentResponse> {
  const result = await api.post<StripeIntentResponse>(
    `${STUDIOS_BASE_URL}/${studioURL}/tips/setupintent`,
    payload
  );
  return result.data;
}

async function getStudioEvents(studioURL: string): Promise<any> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/events`;
  const result = await api.get<
    PaginatedResponse<EventResponse> | EventResponse[]
  >(url);
  return result.data;
}

async function getUpcomingStudioEvents(
  studioURL: string,
  fromDate: moment.Moment,
  is_registered?: boolean
): Promise<any> {
  fromDate = fromDate || moment();
  const url = `${STUDIOS_BASE_URL}/${studioURL}/events/upcoming_events`;
  const result = await api.get<
    PaginatedResponse<EventResponse> | EventResponse[]
  >(url, {
    params: {
      is_registered,
      start_datetime: fromDate.toISOString(),
    },
  });
  return result.data;
}

async function getStudioEventsWithFilter(
  studioURL: string,
  fromDate: moment.Moment,
  toDate: moment.Moment,
  is_registered?: boolean
): Promise<EventResponse[]> {
  const params = {
    from_to_date_0: fromDate.format('YYYY-MM-DD'),
    from_to_date_1: toDate.format('YYYY-MM-DD'),
    is_registered,
  };
  const url = `${STUDIOS_BASE_URL}/${studioURL}/events`;
  const result = await api.get<EventResponse[]>(url, { params });
  return result.data;
}

async function getProductMedia(
  studioURL: string,
  productId: number,
  mediaId: number
): Promise<void> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/favorite-media/${mediaId}`;
  console.log('media url', url);
  const result = await api.get(url);
  return result.data;
}

async function trackProductMediaView(
  studioURL: string,
  productId: number,
  mediaId: number,
  relativeUrl: string
): Promise<void> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/media/${mediaId}/view`;
  await api.post(url, {
    id: mediaId,
    relative_url: relativeUrl,
    product_id: productId,
  });
}

async function getStudentFavourites(studioURL): Promise<FavouritesResponse> {
  const result = await api.get<FavouritesResponse>(
    'api/students/me/my_favorites',
    { params: { studio_name: studioURL } }
  );
  return result.data;
}

async function getInstagramPhotos(studioId): Promise<any> {
  const result = await api.get<any>(
    `${V2_TEACHERS_BASE_URL}/${studioId}/instagram`
  );
  return result.data.data;
}

async function joinStudio(studioURL: string): Promise<any> {
  const result = await api.post(`${STUDIOS_BASE_URL}/${studioURL}/join`);
  return result.data;
}

async function optInToStudioMarketing(
  studioURL: string,
  optIn: boolean
): Promise<any> {
  const result = await api.post(`${STUDIOS_BASE_URL}/${studioURL}/optin`, {
    optin: optIn,
  });
  return result.data;
}

async function addStudentPhoneNumber(
  studioURL: string,
  phone: string
): Promise<any> {
  const payload = {
    phone,
  };
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/add_student_phone_number`,
    payload
  );
  return result.data;
}

async function loadTranslations(
  studioURL: string
): Promise<TranslationsResponse> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/translations`;
  const result = await api.get<TranslationsResponse>(url);
  return result.data;
}

async function saveTranslations(translations: object): Promise<void> {
  await api.post('api/teachers/me/translations', translations);
}

async function getPaymentPlanInvoices(
  studioURL: string,
  id: number
): Promise<StudentPaymentPlanInvoiceResponse[]> {
  const result = await api.get<StudentPaymentPlanInvoiceResponse[]>(
    `${STUDIOS_BASE_URL}/${studioURL}/payment-plans/${id}/invoices`
  );
  return result.data;
}

async function updateStudentPaymentPlanPaymentMethod(
  studioURL: string,
  id: number,
  defaultPaymentMethod: string
): Promise<any> {
  const data = {
    default_payment_method: defaultPaymentMethod,
  };
  const result = await api.post<any>(
    `${STUDIOS_BASE_URL}/${studioURL}/payment-plans/${id}/payment_update`,
    data
  );
  return result.data;
}

async function getMarvelousBio(studioURL: string): Promise<any> {
  const result = await api.get<any>(`${STUDIOS_BASE_URL}/${studioURL}/bio`);
  return result.data;
}

async function getLatestMediaViews(studioURL: string): Promise<any> {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/latest_media_views`
  );
  return result.data;
}

async function getCourseProgress(studioURL: string): Promise<any> {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/course_progress`
  );
  return result.data;
}

async function getWaiverToBeSigned(
  studioURL: string,
  uuid: string
): Promise<any> {
  const result = await api.get(`${STUDIOS_BASE_URL}/${studioURL}/waiver`, {
    params: { uuid },
  });
  return result.data;
}

async function getSignedContracts(studioURL: string): Promise<any> {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/signed-contracts`
  );
  return result.data;
}

async function getPendingSignedContracts(studioURL: string): Promise<any> {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/signed-contracts/pending`
  );
  return result.data;
}

async function getSignedContract(
  studioURL: string,
  uuid: string
): Promise<any> {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/signed-contracts/${uuid}`
  );
  return result.data;
}

async function signContract(
  studioURL: string,
  uuid: string,
  signature: string,
  fullName: string
): Promise<any> {
  const payload = {
    signature,
    full_name: fullName,
  };
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/signed-contracts/${uuid}/sign`,
    payload
  );
  return result.data;
}

async function getGift(
  studioURL: string,
  uuid: string
): Promise<GiftPurchaseResponse> {
  const result = await api.get<GiftPurchaseResponse>(
    `${STUDIOS_BASE_URL}/${studioURL}/gifts/${uuid}`
  );
  return result.data;
}

async function acceptGift(
  studioURL: string,
  uuid: string
): Promise<GiftPurchaseResponse> {
  const result = await api.post<GiftPurchaseResponse>(
    `${STUDIOS_BASE_URL}/${studioURL}/gifts/${uuid}/accept`
  );
  return result.data;
}

async function loadGiftableProducts(
  studioURL: string
): Promise<ProductResponse> {
  const result = await api.get<ProductResponse>(
    `${STUDIOS_BASE_URL}/${studioURL}/products/giftable`
  );
  return result.data;
}

async function addPushToken(studioURL: string, token: string): Promise<any> {
  const result = await api.post<any>(
    `${STUDIOS_BASE_URL}/${studioURL}/pushtoken`,
    { token }
  );
  return result.data;
}

async function pushTokenExists(studioURL: string, token: string): Promise<any> {
  const result = await api.post<any>(
    `${STUDIOS_BASE_URL}/${studioURL}/pushtoken/exists`,
    { token }
  );
  return result.data;
}

async function getStudioUrlForCustomDomain(hostName: string): Promise<any> {
  const result = await api.get<any>(`${V2_TEACHERS_BASE_URL}?domain=${hostName}`);
  return result.data.studio_url;
}


export default {
  getRecentStudios,
  getStudioDetail,
  sendInvitationEmails,
  getLesson,
  getStudioEvents,
  getUpcomingStudioEvents,
  getStudioEventsWithFilter,
  trackProductMediaView,
  getProductMedia,
  getStudentFavourites,
  getInstagramPhotos,
  joinStudio,
  optInToStudioMarketing,
  addStudentPhoneNumber,
  loadTranslations,
  saveTranslations,
  getPaymentPlanInvoices,
  updateStudentPaymentPlanPaymentMethod,
  completeTip,
  createTipSetupIntent,
  getMarvelousBio,
  getLatestMediaViews,
  getCourseProgress,
  getWaiverToBeSigned,
  getSignedContracts,
  getPendingSignedContracts,
  getSignedContract,
  signContract,
  getGift,
  acceptGift,
  loadGiftableProducts,
  addPushToken,
  pushTokenExists,
  getStudioUrlForCustomDomain,
};
