// import * as types from '../mutation-types';
import { defineStore } from 'pinia'
import moment from '@/services/moment';
import { get, set, pick } from 'lodash-es';

import apiStudio from '~/api/studios';
import { useGlobalsStore } from './globals';
// import { generateAllCalendarEvents } from '@/core/calendar';
import templates from '@/utils/studio-templates';
// import { prefetchImages } from '@/mobile/components/utils/image-cache-it';

interface State {
  recentStudios: StudioPreviewResponse[] | null;
  currentStudio: StudioResponse | null;
  currentLesson: LessonResponse | null;
  studioEvents: EventResponse[] | null;
  upcomingStudioEvents: EventResponse[] | null;
  productMediaFavourites: FavouritesResponse[] | null;
  eventStartingSoon: EventResponse | null;
  translations: TranslationsResponse | null;
  currentTranslationsEditLang: string;
  marvelousBio: BioResponse | null;
}

export const useStudioStore = defineStore('studio', {
  state:(): State => {
    return {
      recentStudios: null,
      currentStudio: null,
      currentLesson: null,
      studioEvents: null,
      upcomingStudioEvents: null,
      productMediaFavourites: null,
      eventStartingSoon: null,
      translations: null,
      currentTranslationsEditLang: 'en',
      marvelousBio: null,
    }
  },
  getters: {
  //   purchasedProducts() {
  //     return this.currentStudio?.products.filter(p => p.purchased) || null,
  //   },
    selectedTemplate(): any {
      const previewTemplate = useGlobalsStore().previewTemplate;
      if (previewTemplate) {
        return templates.find(template => template.id === previewTemplate);
      }
      return templates.find(
        template => template.id === this.currentStudio?.selected_template
      );
    },
    alternativePlaybackEnabled(): boolean {
      return !!this.currentStudio?.alternative_playback_enabled;
    },
    isOwner(): boolean {
      return !!this.currentStudio?.is_owner;
    },
    currentStudioId(): number | undefined {
      return this.currentStudio?.id;
    },
    currentStudioWaiver(): StudioWaiverResponse | undefined {
      return this.currentStudio?.waiver;
    },
    isTwilioConnected(): boolean {
      return !!this.currentStudio?.is_twilio_connected;
    },
    currentStudioUrl(): string {
      return this.currentStudio?.studio_url || '';
    },
    currency(): string {
      return this.currentStudio?.currency || '';
    },
    showSignupLinkForStudio(): boolean {
      return !!this.currentStudio?.allow_signup;
    },
    stripePublishableKey(): string | undefined {
      return this.currentStudio?.stripe_publishable_key;
    },
    isStripeProcessor(): boolean {
      return this.currentStudio?.payment_gateway === 'stripe';
    },
  //   // todaysEvents: state => {
  //   //   if (state.studioEvents) {
  //   //     return generateAllCalendarEvents(state.studioEvents).filter(te =>
  //   //       moment(te.date_start).isSame(moment(), 'date')
  //   //     );
  //   //   }
  //   //   return null;
  //   // },
  //   // nextEvent: state => {
  //   //   if (state.studioEvents) {
  //   //     return generateAllCalendarEvents(state.studioEvents).find(te =>
  //   //       moment(te.date_end).isSameOrAfter(moment())
  //   //     );
  //   //   }
  //   //   return null;
  //   // },
  //   // previousLessonMediaId: state => currentLessonMediaId => {
  //   //   if (!currentLessonMediaId || !state.currentLesson?.content) return;
  //   //   const indexOfCurrentLessonMedia = state.currentLesson.content.findIndex(
  //   //     media => media.id == currentLessonMediaId
  //   //   );
  //   //   const previousLessonMediaIndex =
  //   //     (indexOfCurrentLessonMedia + state.currentLesson.content.length - 1) %
  //   //     state.currentLesson.content.length;
  //   //   return state.currentLesson.content[previousLessonMediaIndex]?.id;
  //   // },
  //   // nextLessonMediaId: state => currentLessonMediaId => {
  //   //   if (!currentLessonMediaId || !state.currentLesson?.content) return;
  //   //   const indexOfCurrentLessonMedia = state.currentLesson.content.findIndex(
  //   //     media => media.id == currentLessonMediaId
  //   //   );
  //   //   const nextLessonMediaIndex =
  //   //     (indexOfCurrentLessonMedia + 1) % state.currentLesson.content.length;
  //   //   return state.currentLesson.content[nextLessonMediaIndex]?.id;
  //   // },
    favourites: state => state.productMediaFavourites,
    mediaIconsEnabled() {
      return this.currentStudio?.enable_media_icons;
    },
    communityProfileId() {
      return this.currentStudio?.community_profile_id;
    },
    hasStripe() {
      return this.currentStudio?.payment_gateway === 'stripe';
    },
    hasPayPal() {
      return this.currentStudio?.payment_gateway === 'paypal';
    },
    googleAnalyticsCode(): string | undefined {
      return this.currentStudio?.google_analytics_code;
    },
    rewardfulApiKey(): string | undefined {
      return this.currentStudio?.rewardful_api_key
    },
    isExpired() {
      return this.currentStudio?.is_expired;
    },
  //   hasCustomNavLinks() {
  //     if (this.currentStudio) {
  //       return !!(
  //         this.currentStudio.custom_nav_link_1 ||
  //         this.currentStudio.custom_nav_link_2 ||
  //         this.currentStudio.custom_nav_link_3
  //       );
  //     }
  //     return false;
  //   },
  },
  actions: {
    async getRecentStudios() {
      const recentStudios = await apiStudio.getRecentStudios();

      this.recentStudios = recentStudios;
    },
    async getStudio(studioURL: string) {
      // if (!!studioURL && state.currentStudio?.studio_url != studioURL) {
      //   // reset the flag when visiting other studios
      //   dispatch('community/setInteractAsStudio', false, { root: true });
      // }

      const studio = await apiStudio.getStudioDetail(studioURL);
      this.currentStudio = studio;
      return studio;
    },
  //   async getStudioLesson({ commit }, { studioURL, productId, lessonId }) {
  //     const result = await apiStudio.getLesson(studioURL, productId, lessonId);
  //     commit(types.SET_LESSON_MEDIA, result);
  //   },
    async getStudioEvents(studioURL: string) {
      let events = await apiStudio.getStudioEvents(studioURL);
      // Remove events without product
      if (events.results) {
        events = events.results;
      }
      this.studioEvents = events;
    },
    async getUpcomingStudioEvents(
      { studioURL, fromDate, isRegistered }
    ) {
      let events = await apiStudio.getUpcomingStudioEvents(
        studioURL,
        moment(fromDate),
        isRegistered
      );
      // Remove events without product
      if (events.results) {
        events = events.results;
      }
      this.upcomingStudioEvents = events;
    },
    async getStudioEventsWithFilter(
      { studioURL, fromDate, toDate, isRegistered }
    ) {
      const events = await apiStudio.getStudioEventsWithFilter(
        studioURL,
        fromDate,
        toDate,
        isRegistered
      );
      this.studioEvents = events;
    },
    setEventStartingSoon(event: EventResponse) {
      this.eventStartingSoon = event;
    },
    resetState() {
      Object.assign(state, {}, initialState);
    },
    async sendInvitationEmails(_, payload) {
      await apiStudio.sendInvitationEmails(payload);
    },
    async trackProductMediaView(
      { studioURL, productId, mediaId, relativeUrl }
    ) {
      await apiStudio.trackProductMediaView(
        studioURL,
        productId,
        mediaId,
        relativeUrl
      );
    },
    async getStudentFavourites(studioURL) {
      const favourites = await apiStudio.getStudentFavourites(studioURL);
      this.productMediaFavourites = favourites;
    },
    async getInstagramPhotos(studioId) {
      return await apiStudio.getInstagramPhotos(studioId);
    },
    clearEventStartingSoon() {
      this.eventStartingSoon = null;
    },
    async joinStudio(studioURL: string) {
      return await apiStudio.joinStudio(studioURL);
    },
    async optInToStudioMarketing({ studioURL, optIn }) {
      return await apiStudio.optInToStudioMarketing(studioURL, optIn);
    },
    async addStudentPhoneNumber({ studioURL, phone }) {
      return await apiStudio.addStudentPhoneNumber(studioURL, phone);
    },
    async loadTranslations(studioURL: string) {
      const translations = await apiStudio.loadTranslations(studioURL);
      this.translations = translations;
    },
  //   async saveTranslations({ state }) {
  //     const translations = pick(state.translations, ['translations']);
  //     await apiStudio.saveTranslations(translations);
  //   },
  //   async deleteAllTranslations() {
  //     await apiStudio.saveTranslations({ translations: null });
  //   },
    async getProductMedia({ studioURL, productId, mediaId }) {
      return await apiStudio.getProductMedia(studioURL, productId, mediaId);
    },
    async getPaymentPlanInvoices({ studioURL, paymentPlanId }) {
      return await apiStudio.getPaymentPlanInvoices(studioURL, paymentPlanId);
    },
    async updateStudentPaymentPlanPaymentMethod(
      { studioURL, id, defaultPaymentMethod }
    ) {
      return await apiStudio.updateStudentPaymentPlanPaymentMethod(
        studioURL,
        id,
        defaultPaymentMethod
      );
    },
    async completeTip(payload) {
      const {
        studioURL,
        defaultPaymentMethod,
        stripeCustomerId,
        tipAmount,
        reCaptchaToken,
      } = payload;
      const result = await apiStudio.completeTip(
        studioURL,
        defaultPaymentMethod,
        stripeCustomerId,
        tipAmount,
        reCaptchaToken
      );
      return result;
    },
    async createTipSetupIntent({ studioURL, payload }) {
      const setupIntent = await apiStudio.createTipSetupIntent(
        studioURL,
        payload
      );
      return setupIntent;
    },
  //   async getMarvelousBio({ commit }, studioURL: string) {
  //     const result = await apiStudio.getMarvelousBio(studioURL);
  //     commit(types.SET_MARVELOUS_BIO, result);
  //     return result;
  //   },
    async getLatestMediaViews(studioURL: string) {
      const result = await apiStudio.getLatestMediaViews(studioURL);
      return result;
    },
    async getCourseProgress(studioURL: string) {
      const result = await apiStudio.getCourseProgress(studioURL);
      return result;
    },
    async getWaiverToBeSigned({ studioURL, uuid }) {
      const result = await apiStudio.getWaiverToBeSigned(studioURL, uuid);
      return result;
    },
    async getSignedContracts({ studioURL }) {
      const result = await apiStudio.getSignedContracts(studioURL);
      return result;
    },
    async getPendingSignedContracts({ studioURL }) {
      const result = await apiStudio.getPendingSignedContracts(studioURL);
      return result;
    },
    async getSignedContract({ studioURL, uuid }) {
      const result = await apiStudio.getSignedContract(studioURL, uuid);
      return result;
    },
    async signContract({ studioURL, uuid, signature, fullName }) {
      const result = await apiStudio.signContract(
        studioURL,
        uuid,
        signature,
        fullName
      );
      return result;
    },
    async getGift({ studioURL, uuid }) {
      const result = await apiStudio.getGift(studioURL, uuid);
      return result;
    },
    async acceptGift({ studioURL, uuid }) {
      const result = await apiStudio.acceptGift(studioURL, uuid);
      return result;
    },
    async loadGiftableProducts(studioURL) {
      const result = await apiStudio.loadGiftableProducts(studioURL);
      return result;
    },
    async addPushToken({ studioURL, token }) {
      const result = await apiStudio.addPushToken(studioURL, token);
      return result;
    },
    async pushTokenExists({ studioURL, token }) {
      const result = await apiStudio.pushTokenExists(studioURL, token);
      return result;
    },
  },
  // mutations: {
  //   [types.SET_RECENT_STUDIOS](
  //     state: State,
  //     recentStudios: StudioPreviewResponse[]
  //   ) {
  //     state.recentStudios = recentStudios;
  //   },
  //   [types.SET_CURRENT_STUDIO](state: State, currentStudio: StudioResponse) {
  //     state.currentStudio = currentStudio;
  //   },
  //   [types.SET_LESSON_MEDIA](state: State, data: LessonResponse) {
  //     state.currentLesson = data;
  //   },
  //   [types.SET_STUDIO_EVENTS](state: State, events: EventResponse[]) {
  //     state.studioEvents = events;
  //   },
  //   [types.SET_UPCOMING_STUDIO_EVENTS](state: State, events: EventResponse[]) {
  //     state.upcomingStudioEvents = events;
  //   },
  //   [types.RESET_STUDIO_STATE](state: State) {
  //     Object.assign(state, {}, initialState);
  //   },
  //   [types.SET_FAVOURITES](state: State, favourites: FavouritesResponse[]) {
  //     state.productMediaFavourites = favourites;
  //   },
  //   [types.SET_EVENT_STARTING_SOON](
  //     state: State,
  //     eventStartingSoon: EventResponse
  //   ) {
  //     state.eventStartingSoon = eventStartingSoon;
  //   },
  //   [types.SET_TRANSLATIONS](state: State, translations: TranslationsResponse) {
  //     state.translations = translations;
  //   },
  //   [types.SET_CURRENT_TRANSLATION_LANG](state: State, language: string) {
  //     state.currentTranslationsEditLang = language;
  //   },
  //   [types.SET_SINGLE_TRANSLATION](state: State, translation: any) {
  //     if (!state.translations) {
  //       return;
  //     }
  //     if (!state.translations.translations) {
  //       state.translations.translations = {};
  //     }
  //     set(
  //       state.translations.translations,
  //       translation.translationKey,
  //       translation.translationText
  //     );
  //   },
  //   [types.REMOVE_SINGLE_TRANSLATION](state: State, { translationKey }) {
  //     if (
  //       !state.translations ||
  //       !get(state.translations.translations, translationKey)
  //     ) {
  //       return;
  //     }
  //     set(state.translations.translations, translationKey, undefined);
  //   },
  //   [types.SET_MARVELOUS_BIO](state: State, data: BioResponse) {
  //     state.marvelousBio = { ...state.marvelousBio, ...data };
  //   },
  // },
});