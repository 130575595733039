import { default as about4Ii1gUlCpmMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue?macro=true";
import { default as eventsK8fSvJAms9Meta } from "/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93yUkAdSvFOmMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sampleRMTawVuxhGMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsQQXW5e6T9gMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93Q5gioDRasjMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewxoGS1ImUhJMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexAIRweZzAYeMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93SEiTi92XNUMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexUeuciRYt6gMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93ytGqfkNnS2Meta } from "/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as index3UuEGpV8GQMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsJ7oqRw9afnMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordings3tUYs4eXYfMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsgu579zEuL8Meta } from "/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93cCstbh0YenMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue?macro=true";
import { default as indexEHQzxNmpWnMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/buy/product/[product_id]/index.vue?macro=true";
import { default as confirmHWQs9mYifCMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue?macro=true";
import { default as confirm4vjvfgMidWMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue?macro=true";
import { default as calendartZyyJwhjbWMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/calendar.vue?macro=true";
import { default as chatCdWW3bBCmGMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/chat.vue?macro=true";
import { default as aboutGNHDQIA8IjMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/community-groups/[group_id]/about.vue?macro=true";
import { default as feedAG1qymXQNYMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/community-groups/[group_id]/feed.vue?macro=true";
import { default as members6ieragA5FNMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/community-groups/[group_id]/members.vue?macro=true";
import { default as _91group_id_93RQJF6nma7wMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/community-groups/[group_id].vue?macro=true";
import { default as community_45profile_45editSNyGokPg4NMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/community-profile-edit.vue?macro=true";
import { default as _91id_93wngsrG0shxMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/community-profile/[id].vue?macro=true";
import { default as communityCQageoVVm7Meta } from "/tmp/build_6c2e1c26/pages/[studio_url]/community.vue?macro=true";
import { default as _91uuid_93VAEu0rBYWVMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/complete-registration/[uuid].vue?macro=true";
import { default as _91unique_id_932HEEPlzlgPMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/contracts/[unique_id].vue?macro=true";
import { default as dashboardfMbNGLTyZtMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/dashboard.vue?macro=true";
import { default as indexnkICcJZ8a2Meta } from "/tmp/build_6c2e1c26/pages/[studio_url]/event/details/[event_id]/index.vue?macro=true";
import { default as checkoutegZfSJOjhRMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue?macro=true";
import { default as select_45productteUvxauA4nMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/event/details/[event_id]/select-product.vue?macro=true";
import { default as eventsmLFjwkRlzHMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/events.vue?macro=true";
import { default as _91uuid_93jP2mjRxBqnMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/gifts/[uuid].vue?macro=true";
import { default as indexCkvgspy7KVMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/gifts/index.vue?macro=true";
import { default as _91invitation_id_93tVJSHf1PhYMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/gifts/invitation/[invitation_id].vue?macro=true";
import { default as _91product_id_93JdpE4uRKX2Meta } from "/tmp/build_6c2e1c26/pages/[studio_url]/gifts/product/[product_id].vue?macro=true";
import { default as indexQh5Dh6DTe9Meta } from "/tmp/build_6c2e1c26/pages/[studio_url]/index.vue?macro=true";
import { default as _91invitation_unique_id_937yHeSaUHFFMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/invitation/[invitation_unique_id].vue?macro=true";
import { default as joinSXqtohlKywMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/join.vue?macro=true";
import { default as login1CGRBPlwdCMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/login.vue?macro=true";
import { default as logout3MjKY9zaBzMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/logout.vue?macro=true";
import { default as _91meeting_type_slug_93NteRwec5N1Meta } from "/tmp/build_6c2e1c26/pages/[studio_url]/meet/[meeting_type_slug].vue?macro=true";
import { default as _91meeting_unique_id_938kVrdnDvf5Meta } from "/tmp/build_6c2e1c26/pages/[studio_url]/meet/[meeting_unique_id].vue?macro=true";
import { default as _91meeting_unique_id_93EqhBCqo7EgMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/meeting/[meeting_unique_id].vue?macro=true";
import { default as my_45favoritesUbCHmT1fgmMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/my-favorites.vue?macro=true";
import { default as my_45studioscJeghn52NTMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/my-studios.vue?macro=true";
import { default as optin8MFOof87GMMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/optin.vue?macro=true";
import { default as _91episode_id_93S6yNzVkxV3Meta } from "/tmp/build_6c2e1c26/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue?macro=true";
import { default as indexwCA15xqAORMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/podcasts/[podcast_id]/index.vue?macro=true";
import { default as indexahs6fhxtehMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/podcasts/index.vue?macro=true";
import { default as aboutX2QVaCrQSHMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/about.vue?macro=true";
import { default as digital_45download_45contentIx4bzkpN9XMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/digital-download-content.vue?macro=true";
import { default as events094rlglANSMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93Cr0rkzTMJfMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45samplecJHS20y4xDMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsfrl0IQ5uL4Meta } from "/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93JGcovw4mSsMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewo9GRAfuoswMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as index10wsEzkFCsMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93eGCvvHk5c0Meta } from "/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexPmOyPDFeKQMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93JryjKbWZUcMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexIo5EMxfu7rMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsi8h5AwjzeZMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordings5AzIzzLg1OMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsuzQPqNVsZvMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93FK5d695WDJMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id].vue?macro=true";
import { default as index4qvGObtasaMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/product/index.vue?macro=true";
import { default as indextxVJ4F9GWHMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/profile/index.vue?macro=true";
import { default as purchasesbwDnadgzdHMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/profile/purchases.vue?macro=true";
import { default as redemptionsCgYdcqUQTeMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/profile/redemptions.vue?macro=true";
import { default as _91quiz_id_93HcrmCWQIIYMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/quiz/[quiz_id].vue?macro=true";
import { default as _91token_9392w3jhMboqMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/reset-password/[uid]/[token].vue?macro=true";
import { default as resetd8jZqaXeGMMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/reset.vue?macro=true";
import { default as shopRKKmrRfYGFMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/shop.vue?macro=true";
import { default as signupX2QCRk9WuAMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/signup.vue?macro=true";
import { default as liveBm8ynNMLcFMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/streaming/[event_id]/live.vue?macro=true";
import { default as tipsb5b1wecXy8Meta } from "/tmp/build_6c2e1c26/pages/[studio_url]/tips.vue?macro=true";
import { default as _91uuid_935OjrwDyUPpMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/waiver/[uuid].vue?macro=true";
import { default as compactGDoAzJAV59Meta } from "/tmp/build_6c2e1c26/pages/[studio_url]/widgets/calendars/compact.vue?macro=true";
import { default as fullzy0Ag5kUtfMeta } from "/tmp/build_6c2e1c26/pages/[studio_url]/widgets/calendars/full.vue?macro=true";
export default [
  {
    name: _91product_id_93cCstbh0YenMeta?.name ?? "bundle-bundle_id-product-product_id",
    path: _91product_id_93cCstbh0YenMeta?.path ?? "/bundle/:bundle_id()/product/:product_id()",
    meta: _91product_id_93cCstbh0YenMeta || {},
    alias: _91product_id_93cCstbh0YenMeta?.alias || [],
    redirect: _91product_id_93cCstbh0YenMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: about4Ii1gUlCpmMeta?.name ?? "bundle-bundle_id-product-product_id-about",
    path: about4Ii1gUlCpmMeta?.path ?? "about",
    meta: about4Ii1gUlCpmMeta || {},
    alias: about4Ii1gUlCpmMeta?.alias || [],
    redirect: about4Ii1gUlCpmMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: eventsK8fSvJAms9Meta?.name ?? "bundle-bundle_id-product-product_id-events",
    path: eventsK8fSvJAms9Meta?.path ?? "events",
    meta: eventsK8fSvJAms9Meta || {},
    alias: eventsK8fSvJAms9Meta?.alias || [],
    redirect: eventsK8fSvJAms9Meta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93yUkAdSvFOmMeta?.name ?? "bundle-bundle_id-product-product_id-free-media-media_id",
    path: _91media_id_93yUkAdSvFOmMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93yUkAdSvFOmMeta || {},
    alias: _91media_id_93yUkAdSvFOmMeta?.alias || [],
    redirect: _91media_id_93yUkAdSvFOmMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sampleRMTawVuxhGMeta?.name ?? "bundle-bundle_id-product-product_id-free-sample",
    path: free_45sampleRMTawVuxhGMeta?.path ?? "free-sample",
    meta: free_45sampleRMTawVuxhGMeta || {},
    alias: free_45sampleRMTawVuxhGMeta?.alias || [],
    redirect: free_45sampleRMTawVuxhGMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsQQXW5e6T9gMeta?.name ?? "bundle-bundle_id-product-product_id-included-products",
    path: included_45productsQQXW5e6T9gMeta?.path ?? "included-products",
    meta: included_45productsQQXW5e6T9gMeta || {},
    alias: included_45productsQQXW5e6T9gMeta?.alias || [],
    redirect: included_45productsQQXW5e6T9gMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93Q5gioDRasjMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93Q5gioDRasjMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93Q5gioDRasjMeta || {},
    alias: _91media_id_93Q5gioDRasjMeta?.alias || [],
    redirect: _91media_id_93Q5gioDRasjMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewxoGS1ImUhJMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-view",
    path: viewxoGS1ImUhJMeta?.path ?? "lessons/:lesson_id()/view",
    meta: viewxoGS1ImUhJMeta || {},
    alias: viewxoGS1ImUhJMeta?.alias || [],
    redirect: viewxoGS1ImUhJMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexAIRweZzAYeMeta?.name ?? "bundle-bundle_id-product-product_id-lessons",
    path: indexAIRweZzAYeMeta?.path ?? "lessons",
    meta: indexAIRweZzAYeMeta || {},
    alias: indexAIRweZzAYeMeta?.alias || [],
    redirect: indexAIRweZzAYeMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93SEiTi92XNUMeta?.name ?? "bundle-bundle_id-product-product_id-media-media_id",
    path: _91media_id_93SEiTi92XNUMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93SEiTi92XNUMeta || {},
    alias: _91media_id_93SEiTi92XNUMeta?.alias || [],
    redirect: _91media_id_93SEiTi92XNUMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexUeuciRYt6gMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id",
    path: indexUeuciRYt6gMeta?.path ?? "playlists/:playlist_id()",
    meta: indexUeuciRYt6gMeta || {},
    alias: indexUeuciRYt6gMeta?.alias || [],
    redirect: indexUeuciRYt6gMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93ytGqfkNnS2Meta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93ytGqfkNnS2Meta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93ytGqfkNnS2Meta || {},
    alias: _91media_id_93ytGqfkNnS2Meta?.alias || [],
    redirect: _91media_id_93ytGqfkNnS2Meta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: index3UuEGpV8GQMeta?.name ?? "bundle-bundle_id-product-product_id-playlists",
    path: index3UuEGpV8GQMeta?.path ?? "playlists",
    meta: index3UuEGpV8GQMeta || {},
    alias: index3UuEGpV8GQMeta?.alias || [],
    redirect: index3UuEGpV8GQMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsJ7oqRw9afnMeta?.name ?? "bundle-bundle_id-product-product_id-private-sessions",
    path: private_45sessionsJ7oqRw9afnMeta?.path ?? "private-sessions",
    meta: private_45sessionsJ7oqRw9afnMeta || {},
    alias: private_45sessionsJ7oqRw9afnMeta?.alias || [],
    redirect: private_45sessionsJ7oqRw9afnMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordings3tUYs4eXYfMeta?.name ?? "bundle-bundle_id-product-product_id-recordings",
    path: recordings3tUYs4eXYfMeta?.path ?? "recordings",
    meta: recordings3tUYs4eXYfMeta || {},
    alias: recordings3tUYs4eXYfMeta?.alias || [],
    redirect: recordings3tUYs4eXYfMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsgu579zEuL8Meta?.name ?? "bundle-bundle_id-product-product_id-reviews",
    path: reviewsgu579zEuL8Meta?.path ?? "reviews",
    meta: reviewsgu579zEuL8Meta || {},
    alias: reviewsgu579zEuL8Meta?.alias || [],
    redirect: reviewsgu579zEuL8Meta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexEHQzxNmpWnMeta?.name ?? "buy-product-product_id",
    path: indexEHQzxNmpWnMeta?.path ?? "/buy/product/:product_id()",
    meta: indexEHQzxNmpWnMeta || {},
    alias: indexEHQzxNmpWnMeta?.alias || [],
    redirect: indexEHQzxNmpWnMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/buy/product/[product_id]/index.vue").then(m => m.default || m)
  },
  {
    name: confirmHWQs9mYifCMeta?.name ?? "buy-product-product_id-paypal-confirm",
    path: confirmHWQs9mYifCMeta?.path ?? "/buy/product/:product_id()/paypal/confirm",
    meta: confirmHWQs9mYifCMeta || {},
    alias: confirmHWQs9mYifCMeta?.alias || [],
    redirect: confirmHWQs9mYifCMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirm4vjvfgMidWMeta?.name ?? "buy-product-product_id-stripe-confirm",
    path: confirm4vjvfgMidWMeta?.path ?? "/buy/product/:product_id()/stripe/confirm",
    meta: confirm4vjvfgMidWMeta || {},
    alias: confirm4vjvfgMidWMeta?.alias || [],
    redirect: confirm4vjvfgMidWMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue").then(m => m.default || m)
  },
  {
    name: calendartZyyJwhjbWMeta?.name ?? "calendar",
    path: calendartZyyJwhjbWMeta?.path ?? "/calendar",
    meta: calendartZyyJwhjbWMeta || {},
    alias: calendartZyyJwhjbWMeta?.alias || [],
    redirect: calendartZyyJwhjbWMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/calendar.vue").then(m => m.default || m)
  },
  {
    name: chatCdWW3bBCmGMeta?.name ?? "chat",
    path: chatCdWW3bBCmGMeta?.path ?? "/chat",
    meta: chatCdWW3bBCmGMeta || {},
    alias: chatCdWW3bBCmGMeta?.alias || [],
    redirect: chatCdWW3bBCmGMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/chat.vue").then(m => m.default || m)
  },
  {
    name: _91group_id_93RQJF6nma7wMeta?.name ?? "community-groups-group_id",
    path: _91group_id_93RQJF6nma7wMeta?.path ?? "/community-groups/:group_id()",
    meta: _91group_id_93RQJF6nma7wMeta || {},
    alias: _91group_id_93RQJF6nma7wMeta?.alias || [],
    redirect: _91group_id_93RQJF6nma7wMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/community-groups/[group_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutGNHDQIA8IjMeta?.name ?? "community-groups-group_id-about",
    path: aboutGNHDQIA8IjMeta?.path ?? "about",
    meta: aboutGNHDQIA8IjMeta || {},
    alias: aboutGNHDQIA8IjMeta?.alias || [],
    redirect: aboutGNHDQIA8IjMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/community-groups/[group_id]/about.vue").then(m => m.default || m)
  },
  {
    name: feedAG1qymXQNYMeta?.name ?? "community-groups-group_id-feed",
    path: feedAG1qymXQNYMeta?.path ?? "feed",
    meta: feedAG1qymXQNYMeta || {},
    alias: feedAG1qymXQNYMeta?.alias || [],
    redirect: feedAG1qymXQNYMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/community-groups/[group_id]/feed.vue").then(m => m.default || m)
  },
  {
    name: members6ieragA5FNMeta?.name ?? "community-groups-group_id-members",
    path: members6ieragA5FNMeta?.path ?? "members",
    meta: members6ieragA5FNMeta || {},
    alias: members6ieragA5FNMeta?.alias || [],
    redirect: members6ieragA5FNMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/community-groups/[group_id]/members.vue").then(m => m.default || m)
  }
]
  },
  {
    name: community_45profile_45editSNyGokPg4NMeta?.name ?? "community-profile-edit",
    path: community_45profile_45editSNyGokPg4NMeta?.path ?? "/community-profile-edit",
    meta: community_45profile_45editSNyGokPg4NMeta || {},
    alias: community_45profile_45editSNyGokPg4NMeta?.alias || [],
    redirect: community_45profile_45editSNyGokPg4NMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/community-profile-edit.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wngsrG0shxMeta?.name ?? "community-profile-id",
    path: _91id_93wngsrG0shxMeta?.path ?? "/community-profile/:id()",
    meta: _91id_93wngsrG0shxMeta || {},
    alias: _91id_93wngsrG0shxMeta?.alias || [],
    redirect: _91id_93wngsrG0shxMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/community-profile/[id].vue").then(m => m.default || m)
  },
  {
    name: communityCQageoVVm7Meta?.name ?? "community",
    path: communityCQageoVVm7Meta?.path ?? "/community",
    meta: communityCQageoVVm7Meta || {},
    alias: communityCQageoVVm7Meta?.alias || [],
    redirect: communityCQageoVVm7Meta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/community.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93VAEu0rBYWVMeta?.name ?? "complete-registration-uuid",
    path: _91uuid_93VAEu0rBYWVMeta?.path ?? "/complete-registration/:uuid()",
    meta: _91uuid_93VAEu0rBYWVMeta || {},
    alias: _91uuid_93VAEu0rBYWVMeta?.alias || [],
    redirect: _91uuid_93VAEu0rBYWVMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/complete-registration/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91unique_id_932HEEPlzlgPMeta?.name ?? "contracts-unique_id",
    path: _91unique_id_932HEEPlzlgPMeta?.path ?? "/contracts/:unique_id()",
    meta: _91unique_id_932HEEPlzlgPMeta || {},
    alias: _91unique_id_932HEEPlzlgPMeta?.alias || [],
    redirect: _91unique_id_932HEEPlzlgPMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/contracts/[unique_id].vue").then(m => m.default || m)
  },
  {
    name: dashboardfMbNGLTyZtMeta?.name ?? "dashboard",
    path: dashboardfMbNGLTyZtMeta?.path ?? "/dashboard",
    meta: dashboardfMbNGLTyZtMeta || {},
    alias: dashboardfMbNGLTyZtMeta?.alias || [],
    redirect: dashboardfMbNGLTyZtMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexnkICcJZ8a2Meta?.name ?? "event-details-event_id",
    path: indexnkICcJZ8a2Meta?.path ?? "/event/details/:event_id()",
    meta: indexnkICcJZ8a2Meta || {},
    alias: indexnkICcJZ8a2Meta?.alias || [],
    redirect: indexnkICcJZ8a2Meta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/event/details/[event_id]/index.vue").then(m => m.default || m)
  },
  {
    name: checkoutegZfSJOjhRMeta?.name ?? "event-details-event_id-product-product_id-checkout",
    path: checkoutegZfSJOjhRMeta?.path ?? "/event/details/:event_id()/product/:product_id()/checkout",
    meta: checkoutegZfSJOjhRMeta || {},
    alias: checkoutegZfSJOjhRMeta?.alias || [],
    redirect: checkoutegZfSJOjhRMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: select_45productteUvxauA4nMeta?.name ?? "event-details-event_id-select-product",
    path: select_45productteUvxauA4nMeta?.path ?? "/event/details/:event_id()/select-product",
    meta: select_45productteUvxauA4nMeta || {},
    alias: select_45productteUvxauA4nMeta?.alias || [],
    redirect: select_45productteUvxauA4nMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/event/details/[event_id]/select-product.vue").then(m => m.default || m)
  },
  {
    name: eventsmLFjwkRlzHMeta?.name ?? "events",
    path: eventsmLFjwkRlzHMeta?.path ?? "/events",
    meta: eventsmLFjwkRlzHMeta || {},
    alias: eventsmLFjwkRlzHMeta?.alias || [],
    redirect: eventsmLFjwkRlzHMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/events.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93jP2mjRxBqnMeta?.name ?? "gifts-uuid",
    path: _91uuid_93jP2mjRxBqnMeta?.path ?? "/gifts/:uuid()",
    meta: _91uuid_93jP2mjRxBqnMeta || {},
    alias: _91uuid_93jP2mjRxBqnMeta?.alias || [],
    redirect: _91uuid_93jP2mjRxBqnMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/gifts/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexCkvgspy7KVMeta?.name ?? "gifts",
    path: indexCkvgspy7KVMeta?.path ?? "/gifts",
    meta: indexCkvgspy7KVMeta || {},
    alias: indexCkvgspy7KVMeta?.alias || [],
    redirect: indexCkvgspy7KVMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/gifts/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_id_93tVJSHf1PhYMeta?.name ?? "gifts-invitation-invitation_id",
    path: _91invitation_id_93tVJSHf1PhYMeta?.path ?? "/gifts/invitation/:invitation_id()",
    meta: _91invitation_id_93tVJSHf1PhYMeta || {},
    alias: _91invitation_id_93tVJSHf1PhYMeta?.alias || [],
    redirect: _91invitation_id_93tVJSHf1PhYMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/gifts/invitation/[invitation_id].vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93JdpE4uRKX2Meta?.name ?? "gifts-product-product_id",
    path: _91product_id_93JdpE4uRKX2Meta?.path ?? "/gifts/product/:product_id()",
    meta: _91product_id_93JdpE4uRKX2Meta || {},
    alias: _91product_id_93JdpE4uRKX2Meta?.alias || [],
    redirect: _91product_id_93JdpE4uRKX2Meta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/gifts/product/[product_id].vue").then(m => m.default || m)
  },
  {
    name: indexQh5Dh6DTe9Meta?.name ?? "index",
    path: indexQh5Dh6DTe9Meta?.path ?? "/",
    meta: indexQh5Dh6DTe9Meta || {},
    alias: indexQh5Dh6DTe9Meta?.alias || [],
    redirect: indexQh5Dh6DTe9Meta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_unique_id_937yHeSaUHFFMeta?.name ?? "invitation-invitation_unique_id",
    path: _91invitation_unique_id_937yHeSaUHFFMeta?.path ?? "/invitation/:invitation_unique_id()",
    meta: _91invitation_unique_id_937yHeSaUHFFMeta || {},
    alias: _91invitation_unique_id_937yHeSaUHFFMeta?.alias || [],
    redirect: _91invitation_unique_id_937yHeSaUHFFMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/invitation/[invitation_unique_id].vue").then(m => m.default || m)
  },
  {
    name: joinSXqtohlKywMeta?.name ?? "join",
    path: joinSXqtohlKywMeta?.path ?? "/join",
    meta: joinSXqtohlKywMeta || {},
    alias: joinSXqtohlKywMeta?.alias || [],
    redirect: joinSXqtohlKywMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/join.vue").then(m => m.default || m)
  },
  {
    name: login1CGRBPlwdCMeta?.name ?? "login",
    path: login1CGRBPlwdCMeta?.path ?? "/login",
    meta: login1CGRBPlwdCMeta || {},
    alias: login1CGRBPlwdCMeta?.alias || [],
    redirect: login1CGRBPlwdCMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/login.vue").then(m => m.default || m)
  },
  {
    name: logout3MjKY9zaBzMeta?.name ?? "logout",
    path: logout3MjKY9zaBzMeta?.path ?? "/logout",
    meta: logout3MjKY9zaBzMeta || {},
    alias: logout3MjKY9zaBzMeta?.alias || [],
    redirect: logout3MjKY9zaBzMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/logout.vue").then(m => m.default || m)
  },
  {
    name: _91meeting_type_slug_93NteRwec5N1Meta?.name ?? "meet-meeting_type_slug",
    path: _91meeting_type_slug_93NteRwec5N1Meta?.path ?? "/meet/:meeting_type_slug()",
    meta: _91meeting_type_slug_93NteRwec5N1Meta || {},
    alias: _91meeting_type_slug_93NteRwec5N1Meta?.alias || [],
    redirect: _91meeting_type_slug_93NteRwec5N1Meta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/meet/[meeting_type_slug].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_938kVrdnDvf5Meta?.name ?? "meet-meeting_unique_id",
    path: _91meeting_unique_id_938kVrdnDvf5Meta?.path ?? "/meet/:meeting_unique_id()",
    meta: _91meeting_unique_id_938kVrdnDvf5Meta || {},
    alias: _91meeting_unique_id_938kVrdnDvf5Meta?.alias || [],
    redirect: _91meeting_unique_id_938kVrdnDvf5Meta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/meet/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93EqhBCqo7EgMeta?.name ?? "meeting-meeting_unique_id",
    path: _91meeting_unique_id_93EqhBCqo7EgMeta?.path ?? "/meeting/:meeting_unique_id()",
    meta: _91meeting_unique_id_93EqhBCqo7EgMeta || {},
    alias: _91meeting_unique_id_93EqhBCqo7EgMeta?.alias || [],
    redirect: _91meeting_unique_id_93EqhBCqo7EgMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/meeting/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesUbCHmT1fgmMeta?.name ?? "my-favorites",
    path: my_45favoritesUbCHmT1fgmMeta?.path ?? "/my-favorites",
    meta: my_45favoritesUbCHmT1fgmMeta || {},
    alias: my_45favoritesUbCHmT1fgmMeta?.alias || [],
    redirect: my_45favoritesUbCHmT1fgmMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: my_45studioscJeghn52NTMeta?.name ?? "my-studios",
    path: my_45studioscJeghn52NTMeta?.path ?? "/my-studios",
    meta: my_45studioscJeghn52NTMeta || {},
    alias: my_45studioscJeghn52NTMeta?.alias || [],
    redirect: my_45studioscJeghn52NTMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/my-studios.vue").then(m => m.default || m)
  },
  {
    name: optin8MFOof87GMMeta?.name ?? "optin",
    path: optin8MFOof87GMMeta?.path ?? "/optin",
    meta: optin8MFOof87GMMeta || {},
    alias: optin8MFOof87GMMeta?.alias || [],
    redirect: optin8MFOof87GMMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/optin.vue").then(m => m.default || m)
  },
  {
    name: _91episode_id_93S6yNzVkxV3Meta?.name ?? "podcasts-podcast_id-episodes-episode_id",
    path: _91episode_id_93S6yNzVkxV3Meta?.path ?? "/podcasts/:podcast_id()/episodes/:episode_id()",
    meta: _91episode_id_93S6yNzVkxV3Meta || {},
    alias: _91episode_id_93S6yNzVkxV3Meta?.alias || [],
    redirect: _91episode_id_93S6yNzVkxV3Meta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue").then(m => m.default || m)
  },
  {
    name: indexwCA15xqAORMeta?.name ?? "podcasts-podcast_id",
    path: indexwCA15xqAORMeta?.path ?? "/podcasts/:podcast_id()",
    meta: indexwCA15xqAORMeta || {},
    alias: indexwCA15xqAORMeta?.alias || [],
    redirect: indexwCA15xqAORMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/podcasts/[podcast_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexahs6fhxtehMeta?.name ?? "podcasts",
    path: indexahs6fhxtehMeta?.path ?? "/podcasts",
    meta: indexahs6fhxtehMeta || {},
    alias: indexahs6fhxtehMeta?.alias || [],
    redirect: indexahs6fhxtehMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/podcasts/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93FK5d695WDJMeta?.name ?? "product-product_id",
    path: _91product_id_93FK5d695WDJMeta?.path ?? "/product/:product_id()",
    meta: _91product_id_93FK5d695WDJMeta || {},
    alias: _91product_id_93FK5d695WDJMeta?.alias || [],
    redirect: _91product_id_93FK5d695WDJMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutX2QVaCrQSHMeta?.name ?? "product-product_id-about",
    path: aboutX2QVaCrQSHMeta?.path ?? "about",
    meta: aboutX2QVaCrQSHMeta || {},
    alias: aboutX2QVaCrQSHMeta?.alias || [],
    redirect: aboutX2QVaCrQSHMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: digital_45download_45contentIx4bzkpN9XMeta?.name ?? "product-product_id-digital-download-content",
    path: digital_45download_45contentIx4bzkpN9XMeta?.path ?? "digital-download-content",
    meta: digital_45download_45contentIx4bzkpN9XMeta || {},
    alias: digital_45download_45contentIx4bzkpN9XMeta?.alias || [],
    redirect: digital_45download_45contentIx4bzkpN9XMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/digital-download-content.vue").then(m => m.default || m)
  },
  {
    name: events094rlglANSMeta?.name ?? "product-product_id-events",
    path: events094rlglANSMeta?.path ?? "events",
    meta: events094rlglANSMeta || {},
    alias: events094rlglANSMeta?.alias || [],
    redirect: events094rlglANSMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93Cr0rkzTMJfMeta?.name ?? "product-product_id-free-media-media_id",
    path: _91media_id_93Cr0rkzTMJfMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93Cr0rkzTMJfMeta || {},
    alias: _91media_id_93Cr0rkzTMJfMeta?.alias || [],
    redirect: _91media_id_93Cr0rkzTMJfMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45samplecJHS20y4xDMeta?.name ?? "product-product_id-free-sample",
    path: free_45samplecJHS20y4xDMeta?.path ?? "free-sample",
    meta: free_45samplecJHS20y4xDMeta || {},
    alias: free_45samplecJHS20y4xDMeta?.alias || [],
    redirect: free_45samplecJHS20y4xDMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsfrl0IQ5uL4Meta?.name ?? "product-product_id-included-products",
    path: included_45productsfrl0IQ5uL4Meta?.path ?? "included-products",
    meta: included_45productsfrl0IQ5uL4Meta || {},
    alias: included_45productsfrl0IQ5uL4Meta?.alias || [],
    redirect: included_45productsfrl0IQ5uL4Meta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93JGcovw4mSsMeta?.name ?? "product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93JGcovw4mSsMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93JGcovw4mSsMeta || {},
    alias: _91media_id_93JGcovw4mSsMeta?.alias || [],
    redirect: _91media_id_93JGcovw4mSsMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewo9GRAfuoswMeta?.name ?? "product-product_id-lessons-lesson_id-view",
    path: viewo9GRAfuoswMeta?.path ?? "lessons/:lesson_id()/view",
    meta: viewo9GRAfuoswMeta || {},
    alias: viewo9GRAfuoswMeta?.alias || [],
    redirect: viewo9GRAfuoswMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: index10wsEzkFCsMeta?.name ?? "product-product_id-lessons",
    path: index10wsEzkFCsMeta?.path ?? "lessons",
    meta: index10wsEzkFCsMeta || {},
    alias: index10wsEzkFCsMeta?.alias || [],
    redirect: index10wsEzkFCsMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93eGCvvHk5c0Meta?.name ?? "product-product_id-media-media_id",
    path: _91media_id_93eGCvvHk5c0Meta?.path ?? "media/:media_id()",
    meta: _91media_id_93eGCvvHk5c0Meta || {},
    alias: _91media_id_93eGCvvHk5c0Meta?.alias || [],
    redirect: _91media_id_93eGCvvHk5c0Meta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexPmOyPDFeKQMeta?.name ?? "product-product_id-playlists-playlist_id",
    path: indexPmOyPDFeKQMeta?.path ?? "playlists/:playlist_id()",
    meta: indexPmOyPDFeKQMeta || {},
    alias: indexPmOyPDFeKQMeta?.alias || [],
    redirect: indexPmOyPDFeKQMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93JryjKbWZUcMeta?.name ?? "product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93JryjKbWZUcMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93JryjKbWZUcMeta || {},
    alias: _91media_id_93JryjKbWZUcMeta?.alias || [],
    redirect: _91media_id_93JryjKbWZUcMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexIo5EMxfu7rMeta?.name ?? "product-product_id-playlists",
    path: indexIo5EMxfu7rMeta?.path ?? "playlists",
    meta: indexIo5EMxfu7rMeta || {},
    alias: indexIo5EMxfu7rMeta?.alias || [],
    redirect: indexIo5EMxfu7rMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsi8h5AwjzeZMeta?.name ?? "product-product_id-private-sessions",
    path: private_45sessionsi8h5AwjzeZMeta?.path ?? "private-sessions",
    meta: private_45sessionsi8h5AwjzeZMeta || {},
    alias: private_45sessionsi8h5AwjzeZMeta?.alias || [],
    redirect: private_45sessionsi8h5AwjzeZMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordings5AzIzzLg1OMeta?.name ?? "product-product_id-recordings",
    path: recordings5AzIzzLg1OMeta?.path ?? "recordings",
    meta: recordings5AzIzzLg1OMeta || {},
    alias: recordings5AzIzzLg1OMeta?.alias || [],
    redirect: recordings5AzIzzLg1OMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsuzQPqNVsZvMeta?.name ?? "product-product_id-reviews",
    path: reviewsuzQPqNVsZvMeta?.path ?? "reviews",
    meta: reviewsuzQPqNVsZvMeta || {},
    alias: reviewsuzQPqNVsZvMeta?.alias || [],
    redirect: reviewsuzQPqNVsZvMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index4qvGObtasaMeta?.name ?? "product",
    path: index4qvGObtasaMeta?.path ?? "/product",
    meta: index4qvGObtasaMeta || {},
    alias: index4qvGObtasaMeta?.alias || [],
    redirect: index4qvGObtasaMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/product/index.vue").then(m => m.default || m)
  },
  {
    name: indextxVJ4F9GWHMeta?.name ?? "profile",
    path: indextxVJ4F9GWHMeta?.path ?? "/profile",
    meta: indextxVJ4F9GWHMeta || {},
    alias: indextxVJ4F9GWHMeta?.alias || [],
    redirect: indextxVJ4F9GWHMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/profile/index.vue").then(m => m.default || m)
  },
  {
    name: purchasesbwDnadgzdHMeta?.name ?? "profile-purchases",
    path: purchasesbwDnadgzdHMeta?.path ?? "/profile/purchases",
    meta: purchasesbwDnadgzdHMeta || {},
    alias: purchasesbwDnadgzdHMeta?.alias || [],
    redirect: purchasesbwDnadgzdHMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/profile/purchases.vue").then(m => m.default || m)
  },
  {
    name: redemptionsCgYdcqUQTeMeta?.name ?? "profile-redemptions",
    path: redemptionsCgYdcqUQTeMeta?.path ?? "/profile/redemptions",
    meta: redemptionsCgYdcqUQTeMeta || {},
    alias: redemptionsCgYdcqUQTeMeta?.alias || [],
    redirect: redemptionsCgYdcqUQTeMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/profile/redemptions.vue").then(m => m.default || m)
  },
  {
    name: _91quiz_id_93HcrmCWQIIYMeta?.name ?? "quiz-quiz_id",
    path: _91quiz_id_93HcrmCWQIIYMeta?.path ?? "/quiz/:quiz_id()",
    meta: _91quiz_id_93HcrmCWQIIYMeta || {},
    alias: _91quiz_id_93HcrmCWQIIYMeta?.alias || [],
    redirect: _91quiz_id_93HcrmCWQIIYMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/quiz/[quiz_id].vue").then(m => m.default || m)
  },
  {
    name: _91token_9392w3jhMboqMeta?.name ?? "reset-password-uid-token",
    path: _91token_9392w3jhMboqMeta?.path ?? "/reset-password/:uid()/:token()",
    meta: _91token_9392w3jhMboqMeta || {},
    alias: _91token_9392w3jhMboqMeta?.alias || [],
    redirect: _91token_9392w3jhMboqMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/reset-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: resetd8jZqaXeGMMeta?.name ?? "reset",
    path: resetd8jZqaXeGMMeta?.path ?? "/reset",
    meta: resetd8jZqaXeGMMeta || {},
    alias: resetd8jZqaXeGMMeta?.alias || [],
    redirect: resetd8jZqaXeGMMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/reset.vue").then(m => m.default || m)
  },
  {
    name: shopRKKmrRfYGFMeta?.name ?? "shop",
    path: shopRKKmrRfYGFMeta?.path ?? "/shop",
    meta: shopRKKmrRfYGFMeta || {},
    alias: shopRKKmrRfYGFMeta?.alias || [],
    redirect: shopRKKmrRfYGFMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/shop.vue").then(m => m.default || m)
  },
  {
    name: signupX2QCRk9WuAMeta?.name ?? "signup",
    path: signupX2QCRk9WuAMeta?.path ?? "/signup",
    meta: signupX2QCRk9WuAMeta || {},
    alias: signupX2QCRk9WuAMeta?.alias || [],
    redirect: signupX2QCRk9WuAMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/signup.vue").then(m => m.default || m)
  },
  {
    name: liveBm8ynNMLcFMeta?.name ?? "streaming-event_id-live",
    path: liveBm8ynNMLcFMeta?.path ?? "/streaming/:event_id()/live",
    meta: liveBm8ynNMLcFMeta || {},
    alias: liveBm8ynNMLcFMeta?.alias || [],
    redirect: liveBm8ynNMLcFMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/streaming/[event_id]/live.vue").then(m => m.default || m)
  },
  {
    name: tipsb5b1wecXy8Meta?.name ?? "tips",
    path: tipsb5b1wecXy8Meta?.path ?? "/tips",
    meta: tipsb5b1wecXy8Meta || {},
    alias: tipsb5b1wecXy8Meta?.alias || [],
    redirect: tipsb5b1wecXy8Meta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/tips.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_935OjrwDyUPpMeta?.name ?? "waiver-uuid",
    path: _91uuid_935OjrwDyUPpMeta?.path ?? "/waiver/:uuid()",
    meta: _91uuid_935OjrwDyUPpMeta || {},
    alias: _91uuid_935OjrwDyUPpMeta?.alias || [],
    redirect: _91uuid_935OjrwDyUPpMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/waiver/[uuid].vue").then(m => m.default || m)
  },
  {
    name: compactGDoAzJAV59Meta?.name ?? "widgets-calendars-compact",
    path: compactGDoAzJAV59Meta?.path ?? "/widgets/calendars/compact",
    meta: compactGDoAzJAV59Meta || {},
    alias: compactGDoAzJAV59Meta?.alias || [],
    redirect: compactGDoAzJAV59Meta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/widgets/calendars/compact.vue").then(m => m.default || m)
  },
  {
    name: fullzy0Ag5kUtfMeta?.name ?? "widgets-calendars-full",
    path: fullzy0Ag5kUtfMeta?.path ?? "/widgets/calendars/full",
    meta: fullzy0Ag5kUtfMeta || {},
    alias: fullzy0Ag5kUtfMeta?.alias || [],
    redirect: fullzy0Ag5kUtfMeta?.redirect,
    component: () => import("/tmp/build_6c2e1c26/pages/[studio_url]/widgets/calendars/full.vue").then(m => m.default || m)
  }
]