import moment from '@/services/moment';
import { api } from './api';
import {
  formatObjectForBackend,
  prepareEventForBackend,
} from '@/api/utils/converter';
import _ from 'lodash-es';

// Student
const STUDIOS_BASE_URL = 'api/studios';

async function getStudioEventDetails(
  studioURL: string,
  eventId: number,
  accessCode: string | null
): Promise<EventResponse> {
  const optionalParams = accessCode ? `?access_code=${accessCode}` : '';
  const url = `${STUDIOS_BASE_URL}/${studioURL}/events/${eventId}${optionalParams}`;
  const response = await api.get<EventResponse>(url);
  return response.data;
}

async function getStudioEventBasic(
  studioURL: string,
  eventId: number
): Promise<EventResponse> {
  const response = await api.get<EventResponse>(
    `${STUDIOS_BASE_URL}/${studioURL}/events/${eventId}/basic`
  );
  return response.data;
}

async function registerForEvent(
  studioURL: string,
  eventId: number
): Promise<EventResponse> {
  const res = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/events/${eventId}/event_register`
  );
  return res.data;
}

async function registerForEventWaitlist(
  studioURL: string,
  eventId: number
): Promise<EventResponse> {
  const res = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/events/${eventId}/event_waitlist_register`
  );
  return res.data;
}

async function registerForRecording(
  studioURL: string,
  eventId: number
): Promise<EventResponse> {
  const res = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/events/${eventId}/recording_register`
  );
  return res.data;
}

async function registerForFreeEvent(
  studioURL: string,
  eventId: number,
  email: string,
  first_name: string,
  last_name: string
): Promise<EventResponse> {
  const payload = {
    email,
    first_name,
    last_name,
  };
  const res = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/events/${eventId}/free_event_register`,
    payload
  );
  return res.data;
}

async function unregisterFromEvent(
  studioURL: string,
  eventId: number
): Promise<EventResponse> {
  const res = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/events/${eventId}/event_unregister`
  );
  return res.data;
}

async function unregisterFromEventWaitlist(
  studioURL: string,
  eventId: number
): Promise<EventResponse> {
  const res = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/events/${eventId}/event_waitlist_unregister`
  );
  return res.data;
}

async function markEventAttended(
  studioURL: string,
  eventId: number,
  accessCode: string | null
): Promise<void> {
  const payload = {
    access_code: accessCode,
  };
  const res = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/events/${eventId}/mark_attendance`,
    payload
  );
  return res.data;
}

// Teacher
const CALENDAR_BASE_URL = 'api/events';
const CUSTOMERS_BASE_URL = 'api/customers';

async function getCalendarEvents(
  fromDate: moment.Moment,
  toDate: moment.Moment
): Promise<EventResponse[]> {
  const result = await api.get<EventResponse[]>(
    `${CALENDAR_BASE_URL}?from_to_date_0=${fromDate.format(
      'YYYY-MM-DD'
    )}&from_to_date_1=${toDate.format('YYYY-MM-DD')}`
  );
  result.data.forEach(event => {
    event.start_time = moment(event.event_start_datetime).format('LT');
    event.date = moment(event.event_start_datetime).toDate();
  });
  return result.data;
}

async function getEventDetails(eventId): Promise<EventResponse> {
  const result = await api.get<EventResponse>(
    `${CALENDAR_BASE_URL}/${eventId}`
  );
  const event = result.data;
  if (event.student && event.student.id) {
    event.student.label = `${event.student.full_name} (${event.student.email})`;
  }
  event.start_time = moment(event.event_start_datetime).format('LT');
  event.date = moment(event.event_start_datetime).toDate();
  return event;
}

async function createEvent(data: EventResponse): Promise<EventResponse> {
  const dataClone = prepareEventForBackend(
    formatObjectForBackend(_.cloneDeep(data))
  );
  const result = await api.post<EventResponse>(CALENDAR_BASE_URL, dataClone);
  return result.data;
}

async function updateEvent(data: EventResponse): Promise<EventResponse> {
  const clonedData = _.cloneDeep(data);
  const formattedData = formatObjectForBackend(clonedData);
  const preparedData = prepareEventForBackend(formattedData);
  const result = await api.put<EventResponse>(
    `${CALENDAR_BASE_URL}/${preparedData.id}`,
    preparedData
  );
  return result.data;
}

async function startStreamingSession(eventId: number): Promise<EventResponse> {
  const result = await api.post(
    `${CALENDAR_BASE_URL}/${eventId}/create_session`
  );
  return result.data;
}

async function startSessionRecording(eventId: number): Promise<EventResponse> {
  const result = await api.post(
    `${CALENDAR_BASE_URL}/${eventId}/start_recording`
  );
  return result.data;
}

async function stopSessionRecording(eventId: number): Promise<EventResponse> {
  const result = await api.post(
    `${CALENDAR_BASE_URL}/${eventId}/stop_recording`
  );
  return result.data;
}

async function stopStreamingSession(eventId: number): Promise<EventResponse> {
  const result = await api.post(
    `${CALENDAR_BASE_URL}/${eventId}/cancel_session`
  );
  return result.data;
}

async function createZoomMeeting(eventId: number): Promise<EventResponse> {
  const result = await api.post(
    `${CALENDAR_BASE_URL}/${eventId}/create_meeting`
  );
  return result.data;
}

async function scheduleZoomMeeting(eventId: number): Promise<EventResponse> {
  const result = await api.post(
    `${CALENDAR_BASE_URL}/${eventId}/schedule_meeting`
  );
  return result.data;
}

async function getZoomMeeting(eventId: number): Promise<EventResponse> {
  const result = await api.get(`${CALENDAR_BASE_URL}/${eventId}/get_meeting`);
  return result.data;
}

async function endZoomMeeting(eventId: number): Promise<EventResponse> {
  const result = await api.post(`${CALENDAR_BASE_URL}/${eventId}/end_meeting`);
  return result.data;
}

async function markZoomMeetingStarted(eventId: number): Promise<EventResponse> {
  const result = await api.post(
    `${CALENDAR_BASE_URL}/${eventId}/mark_meeting_started`
  );
  return result.data;
}

async function deleteEvent(eventId: number): Promise<void> {
  await api.delete(`${CALENDAR_BASE_URL}/${eventId}`);
}

async function deleteAllRegistrations(eventId: number) {
  const result = await api.post(
    `${CALENDAR_BASE_URL}/${eventId}/registrations/delete_all_registrations`
  );
  return result.data;
}

async function emailAllRegistrants(
  eventId: number,
  subject: string,
  body: string
) {
  const payload = {
    subject: subject,
    body: body,
  };
  const result = await api.post(
    `${CALENDAR_BASE_URL}/${eventId}/registrations/email_registrants`,
    payload
  );
  return result.data;
}

async function emailOneRegistrant(
  eventId: number,
  registrationId: number,
  subject: string,
  body: string
) {
  const payload = {
    subject: subject,
    body: body,
  };
  const result = await api.post(
    `${CALENDAR_BASE_URL}/${eventId}/registrations/${registrationId}/email_registrant`,
    payload
  );
  return result.data;
}

async function emailWaitlistRegistrants(
  eventId: number,
  subject: string,
  body: string
) {
  const payload = {
    subject: subject,
    body: body,
  };
  const result = await api.post(
    `${CALENDAR_BASE_URL}/${eventId}/waitlist/email_registrants`,
    payload
  );
  return result.data;
}

async function emailOneUser(userId: number, subject: string, body: string) {
  const payload = {
    subject: subject,
    body: body,
  };
  const result = await api.post(
    `${CUSTOMERS_BASE_URL}/${userId}/send_email`,
    payload
  );
  return result.data;
}

async function addEventRegistration(
  eventId,
  studentId,
  allowFree,
  allowOverflow
) {
  const payload = {
    student_id: studentId,
    allow_free: allowFree,
    allow_overflow: allowOverflow,
  };
  const result = await api.post(
    `${CALENDAR_BASE_URL}/${eventId}/registrations`,
    payload
  );
  return result.data;
}

async function addEventWaitlistRegistration(eventId, studentId, allowFree) {
  const payload = {
    student_id: studentId,
    allow_free: allowFree,
  };
  const result = await api.post(
    `${CALENDAR_BASE_URL}/${eventId}/waitlist/register`,
    payload
  );
  return result.data;
}

async function removeEventRegistration(eventId, registrationId) {
  const result = await api.delete(
    `${CALENDAR_BASE_URL}/${eventId}/registrations/${registrationId}`
  );
  return result.data;
}

async function removeEventWaitlistRegistration(eventId, registrationId) {
  const result = await api.delete(
    `${CALENDAR_BASE_URL}/${eventId}/waitlist/${registrationId}`
  );
  return result.data;
}

async function teacherMarkEventAttended(
  eventId: number,
  eventRegistrationId: number
): Promise<void> {
  const res = await api.post(
    `${CALENDAR_BASE_URL}/${eventId}/registrations/${eventRegistrationId}/mark_attendance`
  );
  return res.data;
}

async function teacherRemoveEventAttendance(
  eventId: number,
  eventRegistrationId: number
): Promise<void> {
  const res = await api.post(
    `${CALENDAR_BASE_URL}/${eventId}/registrations/${eventRegistrationId}/remove_attendance`
  );
  return res.data;
}

async function getEventAttendances(eventId) {
  const result = await api.get<AttendanceResponse[]>(
    `${CALENDAR_BASE_URL}/${eventId}/attendances`
  );
  return result.data;
}

async function getEventRecordings(eventId) {
  const result = await api.get<EventRecordingResponse[]>(
    `${CALENDAR_BASE_URL}/${eventId}/recordings`
  );
  return result.data;
}

async function addEventRecording(eventId, fileId) {
  const payload = {
    file: fileId,
  };
  const result = await api.post<EventRecordingResponse[]>(
    `${CALENDAR_BASE_URL}/${eventId}/recordings`,
    payload
  );
  return result.data;
}

async function deleteEventRecording(eventId, recordingId) {
  const result = await api.delete(
    `${CALENDAR_BASE_URL}/${eventId}/recordings/${recordingId}`
  );
  return result.data;
}

async function registerFromWaitlistToEvent(
  eventId,
  waitlistRegistrationId,
  allowFree,
  allowOverflow
) {
  const payload = {
    allow_free: allowFree,
    allow_overflow: allowOverflow,
  };
  const result = await api.post(
    `${CALENDAR_BASE_URL}/${eventId}/waitlist/${waitlistRegistrationId}/register_to_event`,
    payload
  );
  return result;
}

async function exportEventRegistrationEmails(eventId): Promise<BlobPart> {
  const result = await api.get<Promise<BlobPart>>(
    `${CALENDAR_BASE_URL}/${eventId}/export_emails_to_csv`
  );
  return result.data;
}

async function exportEventWaitlistRegistrationEmails(
  eventId
): Promise<BlobPart> {
  const result = await api.get<Promise<BlobPart>>(
    `${CALENDAR_BASE_URL}/${eventId}/waitlist/export_emails_to_csv`
  );
  return result.data;
}

async function emailAllAttendees(
  eventId: number,
  subject: string,
  body: string
) {
  const payload = {
    subject: subject,
    body: body,
  };
  const result = await api.post(
    `${CALENDAR_BASE_URL}/${eventId}/attendances/email_attendees`,
    payload
  );
  return result.data;
}

export default {
  getStudioEventDetails,
  getStudioEventBasic,
  registerForEvent,
  registerForEventWaitlist,
  registerForRecording,
  registerForFreeEvent,
  unregisterFromEvent,
  unregisterFromEventWaitlist,
  markEventAttended,
  getEventDetails,
  getCalendarEvents,
  createEvent,
  updateEvent,
  deleteEvent,
  deleteAllRegistrations,
  emailAllRegistrants,
  emailOneRegistrant,
  emailWaitlistRegistrants,
  emailAllAttendees,
  emailOneUser,
  addEventRegistration,
  addEventWaitlistRegistration,
  removeEventRegistration,
  removeEventWaitlistRegistration,
  getEventAttendances,
  getEventRecordings,
  addEventRecording,
  deleteEventRecording,
  registerFromWaitlistToEvent,
  exportEventRegistrationEmails,
  exportEventWaitlistRegistrationEmails,
  createZoomMeeting,
  endZoomMeeting,
  markZoomMeetingStarted,
  startStreamingSession,
  stopStreamingSession,
  startSessionRecording,
  stopSessionRecording,
  scheduleZoomMeeting,
  getZoomMeeting,
  teacherMarkEventAttended,
  teacherRemoveEventAttendance,
};
